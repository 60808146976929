import React, { useState } from 'react';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';


function EstForm() {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [website, setWebsite] = useState('')
    const [webName, setWebName] = useState('')
    const [description, setDescription] = useState('')
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [msg, setMsg] =useState('')

    function  handleSubmit(event) {

        event.preventDefault();
            const url = '../api/demoList.php'
            // const url = "http://localhost/BrainCrew-frontend/demoList.php"

            let fData = new FormData();
            fData.append('username', username);
            fData.append('email', email);
            fData.append('phone', phone);
            fData.append('website', website);
            fData.append('webName', webName);
            fData.append('description', description);
            fData.append('date', date);
            fData.append('time', time);

            fetch(url, {
                method: 'POST',
                body: fData})
            .then(res=> res.text())
            .then(data=> setMsg(data))
            .catch(error => console.error(error));

            // RESET THE FORM BACK TO STATE;
            setUsername('')
            setEmail('')
            setPhone('')
            setWebsite('')
            setWebName('')
            setDescription('')
            setDate('')
            setTime('')
    }

  return (
      <>
    {/* // <!-- Contact Start --> */}
    {/* <div className="container-fluid py-5"> */}
        <div className="container py-5">
            <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">DEMO</div>
                <h1 className="mb-4">If You Have Any Query, Please Contact Us</h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <div className="wow fadeIn" data-wow-delay="0.3s">
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <input required autoComplete="true" value={username} onChange={(e) => setUsername(e.target.value)} type="text" className="form-control" name='username' id="username" placeholder="Your Name"/>
                                        <label htmlFor="username">Full Names</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <input required autoComplete="true" value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" name='email' id="email" placeholder="Email"/>
                                        <label htmlFor="email">Email</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <input required autoComplete="true" value={phone} onChange={(e) => setPhone(e.target.value)} type="integer" className="form-control" name='phone' id="phone" placeholder="Number"/>
                                        <label htmlFor="phone">Phone Number</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input required autoComplete="true" value={date} onChange={(e) => setDate(e.target.value)} type="date" className="form-control" name='date' id="date" placeholder="date"/>
                                        <label htmlFor="date">date</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input required autoComplete="true" value={time} onChange={(e) => setTime(e.target.value)} type="time" className="form-control" name='time' id="time" placeholder="time"/>
                                        <label htmlFor="time">Time in 24 HRS</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <select required className="form-control bg-white" value={website} onChange={(e) => setWebsite(e.target.value)} name='website' id="website">
                                            <option value="#"></option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        <label htmlFor="website">Do You Have a Website?</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input autoComplete="true" value={webName} onChange={(e) => setWebName(e.target.value)} type="url" className="form-control" name='webName' id="webName" placeholder="Your Website"/>
                                        <label htmlFor="webName">Your Website (https://Website.com)</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea required className="form-control" autoComplete="true" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Describe your needs" name='description' id="description" style={{height: '150px'}}></textarea>
                                        <label htmlFor="description">Describe your needs</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Confirm</button>
                                    <AnimatedOnScroll animationIn="zoomIn" animationOut="zoomOut" animationInDuration={1000}>
                                    <p className='text-success fs-5 text-uppercase p-1'>{msg}</p>
                                </AnimatedOnScroll>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    {/* </div> */}
    {/* <!-- Contact End --> */}
    </>
  )
}

export default EstForm