import React, { useState } from 'react';
import GoToTop from '../../GoToTop';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';

function ContactForm() {
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [subject,setSubject] = useState('');
  const [comments,setComments] = useState('');
  const [msg, setMsg] = useState('')

 
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = 'http://localhost/BrainCrew-frontend/formsubmit.php'
    // const url = '../api/formsubmit.php'
    let fData = new FormData();
    fData.append('name', name);
    fData.append('email', email);
    fData.append('phone', phone);
    fData.append('subject', subject);
    fData.append('comments', comments);
        
    fetch(url, {
        method: 'POST',
        body: fData})
    .then(res=> res.text())
    .then(data=> console.log(data))
    .catch(error => console.error(error));

    // RESET FORM BACK TO STATE
    setName('')
    setEmail('')
    setPhone('')
    setSubject('')
    setComments('')
  }

  return (
    <>
    {/* <!-- Hero Start --> */}
    <div className="container-fluid pt-5 mb-1 hero-header">
        <div className="container pt-5">
            <div className="row g-5 pt-5">
                <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 className="display-4 text-white mb-4 animated slideInRight">Contact Us</h1>
                </div>
                <div className="col-lg-6 align-self-end text-center text-lg-end">
                    <img className="img-fluid animated slideInRight" src="img/blackBusinessMan.png" alt="" style={{maxHeight: "300px"}}/>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Hero End --> */}

    {/* <!-- Contact Start --> */}
    <div className="container-fluid bg-transparent pt-5">
        <div className="container py-3 d-flex align-items-center justify-content-center flex-wrap">
            <div className="text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Contact Us</div>
                <h1 className="mb-4">If You Have Any Query, Please Contact Us</h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    {/* <p className="text-center mb-4">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p> */}
                    <div className="wow fadeIn" data-wow-delay="0.3s">
    <form  onSubmit={handleSubmit}>
        <div className="row g-3">
            <div className="col-md-6">
                <div className="form-floating">
                    <input required autoComplete="true" type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" name='name' placeholder="Your Name"/>
                    <label htmlFor="name">Your Name</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating">
                    <input required autoComplete="true" type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" name='email' placeholder="Your Preferred Email"/>
                    <label htmlFor="email">Your Preferred Email</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating">
                    <input required autoComplete="true" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" name='phone' placeholder="Your Preferred Email"/>
                    <label htmlFor="phone">Phone Number</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating">
                    <input required autoComplete="true" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" name='subject' placeholder="Subject"/>
                    <label htmlFor="subject">Subject</label>
                </div>
            </div>
            <div className="col-12">
                <div className="form-floating">
                    <textarea required className="form-control"  value={comments} onChange={(e) => setComments(e.target.value)} placeholder="Leave a message here" name='comments' style={{height: "150px"}}></textarea>
                    <label htmlFor="comments">Message</label>
                </div>
            </div>
            <div className="col-12">
                <button className="btn btn-primary w-100 py-3" type="submit">Send</button>
                <AnimatedOnScroll animationIn="zoomIn" animationOut="zoomOut" animationInDuration={1000}>
                        <p className='text-success fs-5 text-uppercase p-1'>{msg}</p>
                    </AnimatedOnScroll>
            </div>
        </div>
    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Contact End --> */}
  
    {/* <!-- FAQs Start --> */}
    <div className="container-flui py-5">
        <div className="container py-5">
            <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Popular FAQs</div>
                <h1 className="mb-4">Frequently Asked Questions</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="accordion" id="accordionFAQ1">
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Do you do ERPs and CRMs Intergrations?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body p-3">
                                Yes, we facilitate ERP and CRM integrations, using Odoo as our trusted partner.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.2s">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How long will it take to get a new website?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body p-3">
                                It will take a minimum of 2 days to get a new website.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.3s">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Do you only create HTML websites?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body p-3">
                                    We not only use HTML/CSS to create websites but we also use other modern sophisticated technologies like React.js, Vue, and JQuery.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.4s">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                    Will my website be mobile-friendly?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body p-3">
                                    Yes, all the websites that BrainCrew Technologies creates are easy to use and mobile friendly.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="accordion" id="accordionFAQ2">
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Will you maintain my site for me?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body p-3">
                                    We do maintain websites. You can contact us to get more details.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.6s">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    I’m on a strict budget. Do you have any low cost options?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body p-3">
                                    Yes we have. You can contact us to get more details.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.7s">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Will you maintain my site for me?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body p-3">
                                We do maintain websites. You can contact us to get more details.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item wow fadeIn" data-wow-delay="0.8s">
                            <h2 className="accordion-header" id="headingEight">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    I’m on a strict budget. Do you have any low cost options?
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body p-3">
                                Yes we have. You can contact us to get more details.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- FAQs Start --> */}
    <GoToTop/>
  </>

    
  );
}

export default ContactForm;
