import React from "react";
import aboutImage from "../images/Código fonte da área de trabalho e papel de parede por linguagem de computador com codificação e programação_ _ Foto Premium.jpeg";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import GoToTop from "../GoToTop";

function AboutUs() {
  return (
    <>
    {/* <!-- Hero Start --> */}
    <div className="container-fluid pt-5 mb-1 hero-header">
        <div className="container pt-5">
            <div className="row g-5 pt-5">
                <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                  <h1 className="display-4 text-white mb-4 animated slideInRight">About Us</h1>
                </div>
                <div className="col-lg-6 align-self-end text-center text-lg-end">
                  <img className="img-fluid animated slideInRight" src="img/blackBusinessMan.png" alt="" style={{maxHeight: "300px"}}/>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Hero End --> */}

    <div className="container-fluid bg-transparent py-5" >
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <AnimatedOnScroll animationIn="bounceInLeft">
            <div className="about-img">
              <img className="img-fluid" src={aboutImage} alt="" />
            </div>
              </AnimatedOnScroll>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <AnimatedOnScroll animationIn="bounceInRight">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              About Us
            </div>
            <h1 className="mb-4">
              We Make Your Business Smarter with Brain Crew Technologies
            </h1>
            <p className="mb-4">
              At Brain Crew Technologies, we are the architects of digital
              transformation. Our mission is to empower businesses with
              innovative solutions, and our vision is to lead the way in
              technological advancement. With a strong focus on Odoo and a
              commitment to excellence, we're your trusted partner on the
              journey to success.
            </p>
            {/* <p className="mb-4">Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no
                        labore lorem sit. Sanctus clita duo justo et tempor.</p> */}
            <div className="row g-3">
              <div className="col-sm-6">
                <h6 className="mb-3">
                  <i className="fa fa-check text-primary me-2"></i>Odoo Partners
                </h6>
                <h6 className="mb-0">
                  <i className="fa fa-check text-primary me-2"></i>Professional
                  Staff
                </h6>
              </div>
              <div className="col-sm-6">
                <h6 className="mb-3">
                  <i className="fa fa-check text-primary me-2"></i>24/7 Support
                </h6>
                <h6 className="mb-0">
                  <i className="fa fa-check text-primary me-2"></i>Fair Prices
                </h6>
              </div>
            </div>
            <div className="d-flex align-items-center mt-4">
              {/* <a className="btn btn-primary rounded-pill px-4 me-3" href="#default">
                Read More
              </a> */}
              <a className="btn btn-primary btn-square me-3" href="#default">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="btn btn-outline-primary btn-square me-3"
                href="#default"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-outline-primary btn-square me-3"
                href="#default"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-outline-primary btn-square"
                href="#default"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </AnimatedOnScroll>
          </div>
        </div>
      </div>
    </div>

{/* <!-- Team Start --> */}
<div className="container-fluid bg-light py-1">
        <div className="container py-3">
            <div className="d-flex flex-column g-5 align-items-center">
                <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Team</div>
                    <h1 className="mb-4">Meet Our Experienced Team</h1>
                    {/* <a className="btn btn-primary rounded-pill px-4" href="#default">Read More</a> */}
                </div>
                <div className="col-lg-12">
                    <div className="row g-4">
                        <div className="col-md-6 pt-md-4">
                            <div className="row ">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                  <AnimatedOnScroll animationIn="bounceInLeft">
                                    <div className="team-item ceoBg text-center rounded p-4 pt-0">
                                        {/* <img className="img-fluid rounded-circle p-4" src="img/team-2.jpg" alt=""/> */}
                                        <h5 className="mb-0">Bill Waweru</h5>
                                        <p>CEO of Company</p>
                                        <p>"As the CEO of Brain Crew Technologies Ltd. I am proud to lead a 
                                          team of dedicated professionals committed to transforming businesses 
                                          through technology. Our relentless pursuit of innovation, client-centric 
                                          approach, and unwavering dedication to excellence drive us to new heights. 
                                          Together, we aim to shape the future of technology and empower businesses for success."</p>
                                        <div className="d-flex justify-content-center mt-3">
                                        <AnimatedOnScroll animationIn="zoomIn" style={{animationDuration: '1.5s'}}>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-linkedin-in"></i></a>
                                    </AnimatedOnScroll>
                                        </div>
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 pt-md-4">
                            <div className="row ">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                  <AnimatedOnScroll animationIn="zoomIn">
                                    <div className="team-item technicianBg text-center rounded p-4 pt-0">
                                        {/* <img className="img-fluid rounded-circle p-4" src="img/team-2.jpg" alt=""/> */}
                                        <h5 className="mb-0">Robert Nyandoro</h5>
                                        <p>Project Manager</p>
                                        <p className="pb-4">"As the Technical Director at Brain Crew Technologies Ltd., I'm committed to delivering 
                                          top-quality solutions that exceed your expectations. Our team's expertise and dedication to 
                                          excellence ensure that every project we undertake is of the highest caliber. Your satisfaction 
                                          and success are our driving forces."</p>
                                        <div className="d-flex justify-content-center mt-3">
                                        <AnimatedOnScroll animationIn="zoomIn" style={{animationDuration: '1.5s'}}>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="#default"><i
                                                    className="fab fa-linkedin-in"></i></a>
                                          </AnimatedOnScroll>

                                        </div>
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Team End --> */}

    {/* <!-- Case Start --> */}
    <div className="container-fluid bg-light py-1">
        <div className="container py-5">
            <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Case Study</div>
                <h1 className="mb-4">Explore Our Recent Case Studies</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <AnimatedOnScroll animationIn="zoomIn">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/BMW-X5.png" alt=""/>
                        <a className="case-overlay text-decoration-none" href="https://inchcape.co.ke/" target="_blank" rel="noreferrer">
                            <small className="text-uppercase">automotive industry</small>
                            <h5 className="lh-base text-white mb-3">Inchcape is a leading independent global automotive distributor in 31 countries.
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                    </AnimatedOnScroll>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <AnimatedOnScroll animationIn="zoomIn">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/teaPouring.png" alt=""/>
                        <a className="case-overlay text-decoration-none" href="https://ntfl.co.ke/" target="_blank" rel="noreferrer">
                            <small className="text-uppercase">manufacturing industry</small>
                            <h5 className="lh-base text-white mb-3">NTFL is one of Kenya's most modern tea processing factory, with a fully automated production line.
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                    </AnimatedOnScroll>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                <AnimatedOnScroll animationIn="zoomIn">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/tramogo-1.png" alt=""/>
                        <a className="case-overlay text-decoration-none" href="https://tramigo.com/ke-en/" target="_blank" rel="noreferrer">
                            <small className="text-uppercase">communication industry</small>
                            <h5 className="lh-base text-white mb-3">Tramigo provides complete tracking and fleet management solutions.
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                    </AnimatedOnScroll>
                </div>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <AnimatedOnScroll animationIn="zoomIn">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/snack.png" alt=""/>
                        <a className="case-overlay text-decoration-none" href="https://www.elbbins.com/" target="_blank" rel="noreferrer">
                            <small className="text-uppercase">Processing industry</small>
                            <h5 className="lh-base text-white mb-3">Plan, develop and implement RTM across the country for both General trade and Modern trade.
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                    </AnimatedOnScroll>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <AnimatedOnScroll animationIn="zoomIn">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/buenoMar.png" alt=""/>
                        <a className="case-overlay text-decoration-none" href="https://buenomarket.ke/" target="_blank" rel="noreferrer">
                            <small className="text-uppercase">Fast-moving-Commodity</small>
                            <h5 className="lh-base text-white mb-3">Bueno-Market helps Nairobians to purchase fresh 
                            vegetable and fruit produce online and get them delivered at their door steps. 
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                    </AnimatedOnScroll>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                <AnimatedOnScroll animationIn="zoomIn">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/UBA.png" alt=""/>
                        <a className="case-overlay text-decoration-none" href="https://www.ubakenya.com/" target="_blank" rel="noreferrer">
                            <small className="text-uppercase">finance industry</small>
                            <h5 className="lh-base text-white mb-3">We had the privilege of partnering with UBA Bank to revolutionize 
                            their financial services. Our innovative solutions empowered UBA with cutting-edge technology, streamlining 
                            their operations, and delivering exceptional customer experiences.
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                    </AnimatedOnScroll>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Case End --> */}
    <GoToTop/>
    </>
  );
}

export default AboutUs;
