import { Route, Routes } from "react-router-dom";
import React,{useState, useEffect} from 'react';
import './App.css';
import Footer from './components/Footer';
import Home from './components/CallToaction/Home';
import NavBar from "./components/NavBar";
import Services from './components/Services';
import ContactForm from './components/CallToaction/ContactForm';
import AboutUs from "./components/AboutUs";
import EstimateCTA from './components/CallToaction/EstimateCTA';
import Privacy from "./components/Privacy";
// import Partners from './components/Partners';
// import Portfolio from './components/Portfolio';
// import Team from './components/Team';

function App() {
  const [navColor, setNavColor] = useState('transparent');
  const [textColor, setTextColor] = useState('white');

  const listenScrollEvent = () => {
    window.scrollY > 10 ? setNavColor("#808080cb") : setNavColor("transparent");
    window.scrollY > 10 ? setTextColor("#ffffffe6") : setTextColor("white");
  };

  useEffect(() => {
    window.addEventListener( "scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  
  return (
    <div className='App d-flex flex-column'>
      <NavBar  navColor={navColor} textColor={textColor}/>
      <Routes>
        <Route path="/demo" element={<EstimateCTA/>}/>
        <Route path='/about_us' element={<AboutUs/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/contact' element={<ContactForm />}/>
        <Route path='/privacy' element={<Privacy />}/>
        <Route exact='true' path='/' element={<Home/>}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;