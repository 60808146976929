import React from 'react'
import GoToTop from '../GoToTop'

function Privacy() {
  return (
    <div className='mt-5 pt-3 '>
        <div className='p-4'>
            <h1>Brain Crew Privacy Policy</h1>
            <p>
            <span>Effective Date: 16th Mar 2024</span><br/>
                Thank you for visiting Brain Crew Technologies website. 
                This Privacy Policy outlines how we collect, use, and protect information obtained from 
                visitors to https://braincrewtechnologies.com/ . By accessing or using our 
                Website, you agree to the terms of this Privacy Policy.
            </p>
            <h5>1. Information We Collect</h5>
            <p>We may collect two types of information:
                <ol>
                    <li className='text-decoration-number'>Personal Identification Data: This includes information you directly provide to us, such as your name, email address, phone number, location, and any other relevant information depending on the context.</li>
                    <li className='text-decoration-number'>Data Generated by Your Activity: This includes log information such as Internet Protocol (IP) address, the time/date/duration of your visit, and other similar information and parameters related to your operating system and computer environment.</li>
                    <li className='text-decoration-number'>Personal Data Relating to Children: Our Website is intended for use by individuals over the age of 18. We do not knowingly solicit or collect personal information from children under the age of 18.</li>
                </ol>
            </p>
            <h5>
                2. How We Collect Your Data
            </h5>
            <p>
                We collect and process data when you:
                Voluntarily complete a survey or provide feedback on any of our message boards or via email, call number, WhatsApp, or chats.
                Use or view our website via your browser’s cookies.
                Register for events, subscribe to newsletters, or make a purchase.
            </p>
            <h5>
            3. Links to Other Websites
            </h5>
            <p>
            Our website may contain links to our accounts on various social media platforms 
            such as Facebook. Please note that we are not responsible for the privacy practices 
            or content of these third-party websites. We encourage you to review the privacy 
            policies of these websites before providing any personal information.
            </p>
            <h5>
            4. Use of Information
            </h5>
            <p>
            We may use the information we collect for various purposes including, but not limited 
            to:
                <ul>
                    <li>Providing and maintaining our services.</li>
                    <li>Communicating with you, including responding to inquiries and providing customer support.</li>
                    <li>Personalizing your experience on our Website.</li>
                    <li>Analyzing trends and statistics to improve our Website and services.</li>
                    <li>Complying with legal obligations.</li>
                </ul>
            </p>
            <h5>5. Protection of Information</h5>
            <p>
            We are committed to protecting the security of your information. 
            We implement appropriate technical and organizational measures to safeguard your 
            personal data against unauthorized access, disclosure, alteration, or destruction.
            </p>
            <h5>
            6. Your Rights
            </h5>
            <p>
            You have the right to:
                <ul>
                    <li>Request access to your personal information.</li>
                    <li>Request correction of your personal information.</li>
                    <li>Request erasure of your personal information.</li>
                    <li>Object to processing of your personal information.</li>
                    <li>Request restriction of processing your personal information.</li>
                    <li>Request transfer of your personal information.</li>
                </ul>
                
                To exercise any of these rights, please contact us using the contact information provided below.
            </p>
            <h5>
            7. Changes to this Privacy Policy
            </h5>
            <p>
            We reserve the right to update or modify this Privacy Policy at any time. 
            Any changes will be effective immediately upon posting the updated Privacy Policy 
            on this page. We encourage you to review this Privacy Policy periodically for any 
            changes.
            </p>
            <h5>
                8. Contact Us
            </h5>
            <p>
                If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                Email: <a href="mailto:info@braincrewtechnologies.com">info@braincrewtechnologies.com</a> Phone: <a href='/'>+254 746 705 721 </a>
                By using our Website, you consent to the terms of this Privacy Policy.
            </p>
        </div>
        <GoToTop/>
    </div>
  )
}

export default Privacy