import React from 'react';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import GoToTop from '../GoToTop';

function Services() {
  return (
    <>
    {/* <!-- Hero Start --> */}
    <div className="container-fluid pt-5 mb-1 hero-header">
        <div className="container pt-5">
            <div className="row g-5 pt-5">
                <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 className="text-white mb-4 animated slideInRight">Our Services</h1>
                </div>
                <div className="col-lg-6 align-self-end text-center text-lg-end">
                    <img className="img-fluid animated slideInRight" src="img/blackBusinessMan.png" alt="man" style={{maxHeight: "300px"}}/>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Hero End --> */}
    
    {/* <!-- Service Start --> */}
    <div className="container-fluid bg-light mt-5 py-5">
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="animated fadeInDown" style={{animationDuration: '3s'}}>
                    <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Services</div>
                    <h1 className="mb-4">Our Excellent Solutions for Your Business</h1>
                    {/* <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam
                        amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus
                        clita duo justo et tempor eirmod magna dolore erat amet</p> */}
                    {/* <a className="btn btn-primary rounded-pill px-4" href="#default">Read More</a> */}
                </div>
                <div className="col-lg-12">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="row g-4">
                                <div className="col-12" >
                                    <AnimatedOnScroll animationIn="fadeInLeft" style={{animationDuration: "1.5s"}}>
                                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div className="service-icon btn-square">
                                            <i className="fa fa-file-code fa-2x"></i>
                                        </div>
                                        <h5 className="mb-3">SOFTWARE DEVELOPMENT</h5>
                                        <p>Harness the power of custom software solutions tailored to your unique requirements. 
                                            We develop cutting-edge applications and platforms that drive efficiency, innovation, 
                                            and business growth.</p>
                                        {/* <a className="btn px-3 mt-auto mx-auto" href="#default">Read More</a> */}
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                <AnimatedOnScroll animationIn="fadeInLeft" style={{animationDuration: "1.5s"}}>
                                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div className="service-icon btn-square">
                                            <i className="fa fa-network-wired fa-2x"></i>
                                        </div>
                                        <h5 className="mb-3">NETWORKING</h5>
                                        <p>Ensure seamless connectivity and data flow within your organization. Our networking solutions are 
                                            designed to optimize your infrastructure,enhance communication, and improve productivity.</p>
                                        {/* <a className="btn px-3 mt-auto mx-auto" href="#default">Read More</a> */}
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-md-4">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                <AnimatedOnScroll animationIn="fadeInRight" style={{animationDuration: "1.5s"}}>
                                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div className="service-icon btn-square">
                                            <i className="fa fa-user-shield fa-2x"></i>
                                        </div>
                                        <h5 className="mb-3">SYSTEM SECURITY</h5>
                                        <p>Safeguard your digital assets with our robust system security services. We employ the 
                                            latest technologies and best practices to protect your data, systems, and sensitive 
                                            information from threats and vulnerabilities.</p>
                                        {/* <a className="btn px-3 mt-auto mx-auto" href="#default">Read More</a> */}
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                                <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                                <AnimatedOnScroll animationIn="fadeInRight" style={{animationDuration: "1.5s"}}>
                                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div className="service-icon btn-square">
                                            <i className="fa fa-laptop-medical fa-2x"></i>
                                        </div>
                                        <h5 className="mb-3">SYSTEM AUDIT</h5>
                                        <p>IT infrastructure's performance and security posture with our expert systems audit services. 
                                            We identify potential weaknesses and recommend solutions to enhance your system's reliability and compliance.</p>
                                        {/* <a className="btn px-3 mt-auto mx-auto" href="#default">Read More</a> */}
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 pt-md-4">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                <AnimatedOnScroll animationIn="fadeInLeft" style={{animationDuration: "1.5s"}}>
                                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div className="service-icon btn-square">
                                            <i className="fa fa-sitemap fa-2x"></i>
                                        </div>
                                        <h5 className="mb-3">IT CONSULTATION</h5>
                                        <p>Tap into our extensive knowledge and experience in the IT industry. Our consultancy 
                                            services provide strategic guidance, helping you make informed decisions to 
                                            optimize your technology investments.</p>
                                        {/* <a className="btn px-3 mt-auto mx-auto" href="#default">Read More</a> */}
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                                
                            </div>
                        </div>    
                        <div className="col-md-6 pt-md-4">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                <AnimatedOnScroll animationIn="fadeInRight" style={{animationDuration: "1.5s"}}>
                                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div className="service-icon btn-square">
                                            <i className="fa fa-headset fa-2x"></i>
                                        </div>
                                        <h5 className="mb-3">SUPPORT AND MAINTENANCE</h5>
                                        <p>We offer ongoing support and maintenance services to ensure your systems and 
                                            applications run smoothly. Our team is ready to assist you whenever you need 
                                            technical assistance or troubleshooting.</p>
                                        {/* <a className="btn px-3 mt-auto mx-auto" href="#default">Read More</a> */}
                                    </div>
                                    </AnimatedOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <GoToTop/>
    </>
  )
}

export default Services