import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import whyChooseUsImage from '../../images/At the office Customizable Isometric Illustrations _ Amico Style-1.png'
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import GoToTop from '../../GoToTop';

function Home() {
    const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [subject,setSubject] = useState('');
  const [comments,setComments] = useState('');
  const [newsletter, setNewsletter] = useState('')
  const [msg, setMsg] = useState('')
  const [newsMsg, setNewsMsg] = useState('')
 
  const handleSubmit = (e) => {
    e.preventDefault();

    const url = '../api/formsubmit.php' 
    // const url = "http://localhost/BrainCrew-frontend/formsubmit.php"
    const fData = new FormData();
    fData.append('name', name);
    fData.append('email', email);
    fData.append('phone', phone);
    fData.append('subject', subject);
    fData.append('comments', comments);

    fetch(url, {
        method: 'POST',
        body: fData})
    .then(res=> res.text())
    .then(data=> setMsg(data))
    .catch(error => console.error(error));

    // RESET FORM BACK TO STATE
    setName('')
    setEmail('')
    setPhone('')
    setSubject('')
    setComments('')
  }

  function handleNewsletterSubmit(e) {
    e.preventDefault();
    const newsUrl = "../api/newsletter.php" 
    // const newsUrl = "http://localhost/BrainCrew-frontend/newsletter.php" 

    const nData = new FormData();
    nData.append('newsletter', newsletter)

    fetch(newsUrl, {
        method: 'POST',
        body: nData})
    .then(res=> res.text())
    .then(data=> setNewsMsg(data))
    .catch(error => console.error(error));

    // axios.post(newsUrl, nData)
    // .then(res => setNewsMsg(res ? "Sent successfully!" : "failed to send"))
    // .catch(error=> console.error(error))

    // RESET FORM BACK TO STATE
    setNewsletter('')
  }

  return (
    <div style={{minHeight: '100vh'}} className='d-flex flex-row justify-content-center align-items-center flex-wrap pt-5 gap-0' id="home" >
        {/* <!-- Hero Start --> */}
        {/* <div className="container-fluid pt-5 hero-header mb-1"> */}
            <div className="container-fluid hero-header mb-1 d-flex flex-wrap align-items-center justify-content-evenly py-5">
            {/* <div className="row g-5 pt-5"> */}
                <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5 pt-5">
                    {/* <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">AI.Tech</div> */}
                    <br/>
                    <h1 className="display-4 text-white mb-4 animated slideInRight">We are <span style={{color: '#090909'}}>Brain Crew Technologies.</span> 
                    <br  />Odoo Champions<br/>
                    <span>Service Provider.</span></h1>
                    <p className="text-white mb-4 animated slideInRight">At Brain Crew Technologies, we're passionate about pushing tech boundaries.
                    From our roots as tech enthusiasts, we've grown into a diverse team of experts
                    dedicated to your success.Our hallmark is excellence, and we specialize in Odoo Intergrations, 
                    software development, IT Services, networking, System Security and Systems Audits.
                    We empower you with cutting-edge tech solutions, making us your trusted partner for success.</p>
                    <AnimatedOnScroll animationIn="zoomIn" animationOut="bounceOut" animationInDuration={800} >
                    <Link to="/demo" className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 my-2" >Request for a demo</Link>
                    <Link to="/contact" className="btn btn-outline-light py-sm-3 px-sm-5 me-3 my-2 rounded-pill" >LET'S GET IN TOUCH</Link>
                    </AnimatedOnScroll>
                </div>
                <div className="pt-3 my-2 animated slideInRight" style={{width: '35em',height: '25em'}}>
                    {/* <img className="img-fluid" src="img/hero-img.png" alt=""/> */}
                    <iframe 
                        className='rounded-5'
                        style={{aspectRatio: '3/2', width: '100%',height: '100%'}}
                        src="https://www.youtube.com/embed/y7TlnAv6cto" 
                        title="What is Odoo? 95 seconds overview" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        {/* </div> */}
        {/* <!-- Hero End --> */}

        {/* <!-- Feature Start --> */}
        <div className="container-fluid bg-info feature p-5 ">
            <div className="d-flex flex-wrap pt-5">
                <div className="row g-0">
                    <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Why Choose Us</div>
                        <h1 className="text-white mb-4">We're Best in the Industry with over 5 Years of Experience</h1>
                        <p className="text-light mb-4">At Brain Crew technologies we can unlock the potential of your business 
                        with our comprehensive suite of services designed to streamline operations and safeguard critical assets.
                        We specialize in:</p>
                        <div className="d-flex align-items-center text-white mb-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span>Seamless connectivity and data flow within organizations.</span>
                        </div>
                        <div className="d-flex align-items-center text-white mb-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span>Digitizing businesses with custom Software Solutions.</span>
                        </div>
                        <div className="d-flex align-items-center text-white mb-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span>Data protection and secure systems.</span>
                        </div>
                        <div className="row g-4 pt-3">
                            <div className="col-sm-6">
                                <AnimatedOnScroll animationIn="bounceInLeft">
                                <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                    <i className="fa fa-users fa-3x text-white"></i>
                                    <div className="ms-3">
                                        <h2 className="text-white mb-0" data-toggle="counter-up">20</h2>
                                        <p className="text-white mb-0">Happy Clients</p>
                                    </div>
                                </div>
                                </AnimatedOnScroll>
                            </div>
                            <div className="col-sm-6">
                            <AnimatedOnScroll animationIn="bounceIn" animationInDuration={2000}> 
                                <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                    <i className="fa fa-check fa-3x text-white"></i>
                                    <div className="ms-3">
                                        <h2 className="text-white mb-0" data-toggle="counter-up">15</h2>
                                        <p className="text-white mb-0">Projects Completed</p>
                                    </div>
                                </div>
                            </AnimatedOnScroll>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                        <img className="img-fluid" src={whyChooseUsImage} alt=""/>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Feature End --> */}

         {/* <!-- Contact Start --> */}
         <div className="py-5">
        <div className="container py-5">
            <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Contact Us</div>
                <h1 className="mb-4">If You Have Any Query, Please Contact Us</h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <div className="wow fadeIn" data-wow-delay="0.3s">
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input  type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" name='name' placeholder="Your Name"/>
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">  
                                    <div className="form-floating">
                                        <input type="email"  value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" name='email' placeholder="Your Email"/>
                                        <label htmlFor="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-md-6">  
                                    <div className="form-floating">
                                        <input  type="number"  value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" name="phone" placeholder="Your Phone"/>
                                        <label htmlFor="phone">Phone Number</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input  type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" name="subject" placeholder="Subject"/>
                                        <label htmlFor="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea  className="form-control" value={comments} onChange={(e) => setComments(e.target.value)} placeholder="Leave a message here" name="comments" style={{height: "150px"}}></textarea>
                                        <label htmlFor="comments">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                    <AnimatedOnScroll animationIn="zoomIn" animationOut="zoomOut" animationInDuration={1000}>
                                    <p className='text-success fs-5 text-uppercase p-1'>{msg}</p>
                                    </AnimatedOnScroll>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Contact End --> */}

        {/* <!-- Newsletter Start --> */}
        <div className="container-fluid newsletter py-5 mt-1">
            <div className="container">
                <div className="row g-5 align-items-center justify-content-start">
                    <div className="col-md-5 ps-lg-0 ps-0 pt-5 pt-md-0 text-start wow fadeIn" data-wow-delay="0.3s">
                    <AnimatedOnScroll animationIn="slideInLeft" animationInDuration={2000}>
                        <img className="img-fluid" src="img/newsletter.png" alt=""/>
                    </AnimatedOnScroll>
                    </div>
                    <div className="col-md-7 py-5 newsletter-text wow fadeIn" data-wow-delay="0.5s">
                        <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Newsletter</div>
                        <h1 className="text-white mb-4">Let's subscribe the newsletter</h1>
                        <div className="position-relative w-100 mt-3 mb-2">
                            <form onSubmit={handleNewsletterSubmit} >
                    <AnimatedOnScroll animationIn="bounceIn" animationInDuration={2000}>
                                <input required autoComplete='true' value={newsletter} onChange={(e)=> setNewsletter(e.target.value)} name='newsletter' className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="email" placeholder="Enter Your Email" style={{height: "48px"}}/>
                                <button type="submit" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary fs-4"></i></button>
                    </AnimatedOnScroll>
                            </form>
                        </div>
                        <small className="text-white-50">Sign up for our Newsletter</small>
                        <AnimatedOnScroll animationIn="zoomIn" animationOut="zoomOut" animationInDuration={1000}>
                        <p className='text-white fs-5 text-uppercase'>{newsMsg}</p>
                        </AnimatedOnScroll>
                    </div>
                </div>
            </div>
        </div>
    {/* <!-- Newsletter End --> */}

        {/* <!-- Testimonial Start --> */}
        <div className="container-xxl py-5 " id="testimonial-carousel">
            <div className="container py-5 mx-0">
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                        <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Testimonial</div>
                        <h1 className="mb-4">What Our Clients Say!</h1>
                        {/* <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam
                            amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus
                            clita duo justo et tempor eirmod magna dolore erat amet</p>
                        <a className="btn btn-primary rounded-pill px-4" href="default">Read More</a> */}
                    </div>

                    {/* <div id="carouselExampleInterval" class="carousel slide my-2 rounded-3" data-bs-ride="carousel" style={{width: '50%', boxShadow: '2px 2px 20px grey'}}>
                        <div class="carousel-inner">
                            <div class="carousel-item my-4 contents active" data-bs-interval="5000" >

                                <div class="testimonial-item ps-5" >
                                    <i class="fa fa-quote-left fa-2x text-primary mb-3"></i>
                                    <p class="fs-4 text-start me-4" >Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita </p>
                                    <div class="d-flex align-items-center">
                                        <img class="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial.png"  alt="avatar"
                                            style={{width: '60px', height: '60px'}}/>
                                        <div class="ps-3">
                                            <h5 class="mb-1">Jane doe</h5>
                                            <span>Profession</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item my-4 contents" data-bs-interval="5000">

                                <div class="testimonial-item ps-5" >
                                    <i class="fa fa-quote-left fa-2x text-primary mb-3"></i>
                                    <p class="fs-4 text-start me-4" >Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita </p>
                                    <div class="d-flex align-items-center">
                                        <img class="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial.png" alt="avatar"
                                            style={{width: '60px', height: '60px'}}/>
                                        <div class="ps-3">
                                            <h5 class="mb-1">Jonh Doe</h5>
                                            <span>Profession</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item my-4 contents" data-bs-interval="5000">
                                <div class="testimonial-item ps-5" >
                                    <i class="fa fa-quote-left fa-2x text-primary mb-3"></i>
                                    <p class="fs-4 text-start me-4" >Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita </p>
                                    <div class="d-flex align-items-center">
                                        <img class="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial.png" alt="avatar"
                                            style={{width: '60px', height: '60px'}}/>
                                        <div class="ps-3">
                                            <h5 class="mb-1">Client Name</h5>
                                            <span>Profession</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <span class="carousel-control-next-icon " aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
        {/* <!-- Testimonial End --> */}
    <GoToTop/>
    </div>
  )
}

export default Home


