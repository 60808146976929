import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/BC Logo.png";

function NavBar({ navColor, textColor }) {
  const [isActive, setIsActive] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {document.removeEventListener("mousedown", handleClickOutside)};
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="containerr text-uppercase" style={{
        display: "flex",
        justifyContent: "space-between",
        zIndex: 1,
        backgroundColor: navColor,
        position: "fixed",
        transition: "all 1.25s",
      }}
    >
      <div className="logo" style={{ width: "15%" }}>
        <a href="/">
          <img className="main-logo" src={logo} alt="main-logo" />
        </a>
      </div>

<>
      <div
        ref={navbarRef}
        className={`navigation ${isActive ? "active" : ""}`}
        // style={{ width: "max-content", float: "right" }}
      >
        <NavLink
          className="nav-btn"
          style={{ color: textColor }}
          exact='true'
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          className="nav-btn"
          style={{ color: textColor }}
          to="/about_us"
        >
          About Us
        </NavLink>
        <NavLink
          className="nav-btn"
          style={{ color: textColor }}
          to="/services"
        >
          services
        </NavLink>
        <NavLink
          className="nav-btn"
          style={{ color: textColor }}
          to="/contact">Contact us
        </NavLink>
      </div>

      <div className={`hamburger ${isActive ? "active" : ""}`} onClick={toggleMenu} ref={navbarRef} style={{ backgroundColor: "transparent" }}>
        <span className="barr"></span>
        <span className="barr"></span>
        <span className="barr"></span>
      </div>
      </>
    </div>
  );
}

export default NavBar;
