import React from 'react';
import Container from 'react-bootstrap/Container';
import EstForm from './EstForm';
import GoToTop from '../../GoToTop';

function EstimateCTA() {
  return (
    <>
    {/* <!-- Hero Start --> */}
    <div className="container-fluid pt-5  hero-header">
        <div className="container pt-5">
            <div className="row g-5 pt-5">
                <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 className="display-4 text-white mb-4 animated slideInRight">Request for a Demo</h1>
                </div>
                <div className="col-lg-6 align-self-end text-center text-lg-end">
                    <img className="img-fluid animated slideInRight" src="img/blackBusinessMan.png" alt="" style={{maxHeight: "300px"}}/>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Hero End --> */}
    <Container className='d-flex justify-content-center flex-wrap p-4 rounded-1 my-4'>
        <div className='d-fle'>
          <h1 className='fs-2' style={{color: '#1a1919', fontFamily: 'Audrey'}}>Get to know our project estimate!</h1>
          <p>
              A project estimate is a critical component in the early stages of project planning 
              and management. It involves predicting the resources, time, and costs required to 
              successfully complete a project. This estimation process helps stakeholders make informed 
              decisions, set realistic expectations, and allocate resources effectively. 
              A well-prepared project estimate lays the foundation for project success by providing a 
              roadmap for achieving the desired goals within established constraints. It is a dynamic 
              document that may evolve as the project progresses, but it serves as a valuable tool for 
              project control and management, enabling teams to stay on track and within budget.
          </p>
          {/* <button onClick={handleClick} className='rounded border-0 text-white mb-3' style={{boxShadow: '0 0 10px rgba(0,0,0,0.5)', backgroundColor: '#c4232a'}}>Get A Free Demo</button>
        {showEstForm && <EstForm />} */}
        <EstForm/>
        </div>
    </Container>
    <GoToTop/>
    </>
  )
}

export default EstimateCTA